import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import WalletImage from "./download-wallet-image"

import "./download-wallet.css"

const DownloadWallet = () => (
  <Fragment>
    <div className="container">
    
      <h1 className="download-wallet-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.wallet.title" />
        </span>
      </h1>
      <div className="download-wallet-content">
        <div className="download-image">
          <WalletImage />
        </div>
        <div className="download-wallet-description">
          <p><FormattedMessage id="org.massnet.components.download.wallet.content" /></p>
          <div className="download-wallet-buttons">
            <button className="download-wallet-mobile_message">
              <span><FormattedMessage id="org.massnet.components.download.mobile_message" /></span><br />
              {/* <span className="download-wallet-mac-version"><FormattedMessage id="org.massnet.components.download.wallet.button1.version" /></span> */}
            </button>
            {/* <a href='https://download.massnet.org/download/mass-wallet-full-node-desktop-macos-latest.dmg'>
              <button className="download-wallet-mac">
                <span><FormattedMessage id="org.massnet.components.download.wallet.button1.title" /></span><br />
                <span className="download-wallet-mac-version"><FormattedMessage id="org.massnet.components.download.wallet.button1.version" /></span>
              </button>
            </a>
            <a href='https://download.massnet.org/download/mass-wallet-full-node-desktop-linux-latest.tar.gz'>
              <button className="download-wallet-linux">
                <span><FormattedMessage id="org.massnet.components.download.wallet.button2.title" /></span><br />
                <span className="download-wallet-linux-version"><FormattedMessage id="org.massnet.components.download.wallet.button2.version" /></span>
              </button>
            </a> */}
          </div>
        </div>
        
      </div>
    </div>
  </Fragment>
)

export default DownloadWallet
