import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { StaticQuery, graphql } from "gatsby"

import "./download-code.css"

const DownloadCode = () => (
  <Fragment>
    <div className="container" style={{ marginBottom: "60px" }}>
      <h1 className="download-code-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.code.title" />
        </span>
      </h1>
      <StaticQuery
        query={graphql`
          query {
            minerIcon: file(relativePath: { eq: "miner-code.svg" }) {
              publicURL
            }
            walletIcon: file(relativePath: { eq: "wallet-code.svg" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div className="download-code-content">
            <div className="code-card">
              <img
                alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿"
                src={data.minerIcon.publicURL}
              />
              <h5>
                <FormattedMessage id="org.massnet.components.download.code.miner" />
              </h5>
            </div>
            <div className="code-card">
              <img
                alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿"
                src={data.walletIcon.publicURL}
              />
              <h5>
                <FormattedMessage id="org.massnet.components.download.code.wallet" />
              </h5>
            </div>
          </div>
        )}
      />
      <div className="download-client-description">
        <div className="download-client-buttons">
          {/* <a href='https://download.massnet.org/wallet/mass-release-testnet-v1.tar.gz'> */}
          <button className="download-client-mobile_message">
            <span>
              <FormattedMessage id="org.massnet.components.download.mobile_message" />
            </span>
            <br />
          </button>
          {/* </a> */}
        </div>
      </div>
    </div>
  </Fragment>
)

export default DownloadCode
