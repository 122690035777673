import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderDownload from "../components/header-download"
import FooterNav from "../components/footer-nav"
import DownloadClient from "../components/download-client"
import DownloadWallet from "../components/download-wallet"
import DownloadMiner from "../components/download-miner"
import DownloadCode from "../components/download-code"

const DownloadPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeaderDownload />
    <SEO
      title="Download | MASS Blockchain Consensus Engine"
      keywords={[`MASS Blockchain Consensus Engine`]}
      lang={locale}
    />
    <DownloadWallet />
    <DownloadMiner />
    <DownloadCode />
    {/* <DownloadClient /> */}
    <FooterNav />
  </Layout>
)

DownloadPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default DownloadPage
