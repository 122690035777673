import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import ClientImage from "./download-miner-image"

import "./download-miner.css"

const DownloadClient = () => (
  <Fragment>
    <div className="container" style={{marginBottom: '60px'}}>
      <h1 className="download-miner-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.miner.title" />
        </span>
      </h1>
      <div className="download-miner-content">
        <div className="download-image">
          <ClientImage />
        </div>
        <div className="download-miner-description">
          <p><FormattedMessage id="org.massnet.components.download.miner.content" /></p>
          <div className="download-miner-buttons">
            {/* <a href='https://download.massnet.org/wallet/mass-release-testnet-v1.tar.gz'> */}
              <button className="download-miner-mobile_message">
                <span><FormattedMessage id="org.massnet.components.download.mobile_message" /></span><br />
              </button>
            {/* </a> */}
          </div>
        </div>
        
      </div>
    </div>
  </Fragment>
)

export default DownloadClient
